import { gql } from "@apollo/client";
import { addTypePolicies } from "src/lib/graphql/cache";
import useGQLPaginatedQuery from "src/lib/graphql/hooks/useGQLPaginatedQuery";
import paginatedListFieldPolicy from "src/lib/graphql/utils/paginatedListFieldPolicy";
const DIAGRAM_SEARCH_QUERY = gql `
query EP_diagramSearch($pagination: PaginationOptions!, $query: String, $ancestorID: ID, $topic: String) {
  ep_diagramSearch(pagination: $pagination, topic: $topic, ancestorID: $ancestorID, query: $query) {
    totalCount
    list {
      id
      type
      ctime
      utime
      data {
        imageURL
        title
        description
        topics
      }
      # refQuestions {
      #   id
      #   data {
      #     question
      #   }
      # }
    }
  }
}
`;
export function useDiagramSearchQuery(options) {
    return useGQLPaginatedQuery(DIAGRAM_SEARCH_QUERY, 'ep_diagramSearch', options);
}
addTypePolicies({
    Query: {
        fields: {
            ep_diagramSearch: paginatedListFieldPolicy([
                'query',
                'ancestorID',
                'topic'
            ]),
        },
    },
});
