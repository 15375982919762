import React from 'react';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import useData from './useData';
import GQLPaginatedList from 'src/lib/graphql/components/GQLPaginatedList';
import DiagramListLoading from './DiagramListLoading';
import DiagramListEmpty from './DiagramListEmpty';
import Grid from 'src/components/10-atoms/layout/Grid';
import DiagramItem from './DiagramItem';
import { imageViewHelperActions } from 'src/components/20-helper-views/image-viewer/useImageViewer';
import Row from 'src/components/10-atoms/layout/Row';
import SearchInput from 'src/components/10-atoms/form/inputs/SearchInput';
import styleUtils from 'src/utils/styles';
import { PRESSABLE_SIZE } from 'src/components/10-atoms/pressables/constants';
import Column from 'src/components/10-atoms/layout/Column';
const DiagramsTab = ({ subjectID, onQuestionPress }) => {
    const [{ theme, styles, deviceType }] = useTheme(stylesGenerator);
    const [state, actions] = useData(subjectID);
    const handleDiagramPress = (index) => {
        if (state.listQuery.data) {
            imageViewHelperActions.show({
                images: state.listQuery.data.list.map(diagram => ({
                    uri: diagram.data.imageURL
                })),
                initialIndex: index,
            });
        }
    };
    return (React.createElement(Column, { style: [styleUtils.ovfHidden, styleUtils.flex] },
        React.createElement(GQLPaginatedList, { containerStyle: styles.container, LoaderComponent: React.createElement(DiagramListLoading, { cols: state.gridCols }), ListEmptyComponent: DiagramListEmpty, renderCollapsibleHeader: () => (React.createElement(Row, { bg: theme.palette.background.dark, ph: theme.spacing.page, pv: theme.spacing.standard.xs },
                React.createElement(SearchInput, { containerStyle: styleUtils.flex, style: { backgroundColor: theme.palette.background.main }, placeholder: 'Search for diagrams...', value: state.search.value, onChange: actions.search.change, onClear: () => actions.search.change('') }))), collapsibleHeaderHeight: PRESSABLE_SIZE + 2 * theme.spacing.standard.xs, forceCollapse: deviceType.isPhone, variables: state.listQueryVar, queryState: state.gridListQuery, queryActions: actions.listQuery, keyExtractor: (_, index) => String(index), renderItem: ({ item: diagrams, index: row }) => (React.createElement(Row, { ph: theme.spacing.page, pv: theme.spacing.standard.xxs, pt: row === 0 ? theme.spacing.standard.xs : theme.spacing.standard.xxs },
                React.createElement(Grid, { cols: state.gridCols, flex: true, spacing: theme.spacing.standard.xs }, diagrams.map((diagram, col) => (React.createElement(Row, { style: styles.diagramContainer, key: diagram.id },
                    React.createElement(DiagramItem, { diagram: diagram, onPress: () => handleDiagramPress((row * state.gridCols) + col), pressableStyle: styles.diagramItemContainer }))))))) })));
};
const stylesGenerator = createThemedStyle(({ theme, select }) => ({
    container: {
        flex: 1,
        backgroundColor: select({
            dark: theme.palette.background.main,
            light: theme.palette.background.subtle,
        }),
    },
    diagramContainer: {
        aspectRatio: '1'
    },
    diagramItemContainer: {
        width: '100%',
        height: '100%'
    }
}));
export default DiagramsTab;
