import React from 'react';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import FeatherIcon from '../icons/FeatherIcon';
import FilterChipLayout from './FilterChipLayout';
const DropDownFilterChipLayout = ({ selected, children, ...filterChipProps }) => {
    const [{ theme }] = useTheme(stylesGenerator);
    return (React.createElement(FilterChipLayout, { xAxis: 'space-between', spacing: theme.spacing.standard.xs, ...filterChipProps, bcl: selected
            ? theme.palette.primary.dark
            : theme.palette.border.main, bg: selected
            ? theme.palette.primary.lightest
            : theme.palette.background.main },
        children,
        React.createElement(FeatherIcon, { name: "chevron-down", size: theme.iconSize.xs })));
};
const stylesGenerator = createThemedStyle(({}) => ({}));
export default DropDownFilterChipLayout;
