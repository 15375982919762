import React from 'react';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import BooleanFilterChip from 'src/components/10-atoms/filters/BooleanFilterChip';
import Typography from 'src/components/10-atoms/typography/Typography';
import useI18n from 'src/modules/translations/hooks/useI18n';
const BookmarkedFilter = ({ value, onChange }) => {
    const [{}] = useTheme(stylesGenerator);
    const { translate } = useI18n();
    return (React.createElement(BooleanFilterChip, { value: value, onChange: onChange },
        React.createElement(Typography, null, translate('Bookmarked'))));
};
const stylesGenerator = createThemedStyle(({}) => ({}));
export default BookmarkedFilter;
