import React from 'react';
import withThemeProvider from 'src/customizations/themes/hoc/withThemeProvider';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import Row from '../layout/Row';
import FitImage from './FitImage';
const IllustrationImage = ({ source, ...rowProps }) => {
    const [{ theme }] = useTheme(stylesGenerator);
    return (React.createElement(Row, { bg: theme.palette.background.main, ph: theme.spacing.standard.sm, pv: theme.spacing.standard.sm, brd: theme.borderRadius.standard.lg, ...rowProps },
        React.createElement(FitImage, { source: source })));
};
const stylesGenerator = createThemedStyle(({}) => ({}));
export default withThemeProvider(IllustrationImage, 'light');
