import Clipboard from '@react-native-clipboard/clipboard';
import React, { useEffect, useRef } from 'react';
import Button from 'src/components/10-atoms/button/Button';
import Divider from 'src/components/10-atoms/Divider';
import GenericServerError from 'src/components/10-atoms/GenericServerError';
import BookmarkIcon from 'src/components/10-atoms/icons/BookmarkIcon';
import FeatherIcon from 'src/components/10-atoms/icons/FeatherIcon';
import Column from 'src/components/10-atoms/layout/Column';
import Row from 'src/components/10-atoms/layout/Row';
import CustomScrollView from 'src/components/10-atoms/list-views/CustomScrollView';
import scrollTo from 'src/components/10-atoms/list-views/utils/scrollTo';
import QueryHandler from 'src/components/10-atoms/QueryHandler';
import Tab from 'src/components/10-atoms/tabs/Tab';
import TabHeader from 'src/components/10-atoms/tabs/TabHeader';
import Tabs from 'src/components/10-atoms/tabs/Tabs';
import Typography from 'src/components/10-atoms/typography/Typography';
import { bottomDrawerHelperActions } from 'src/components/20-helper-views/bottom-drawer/useBottomDrawer';
import { snackbarHelperActions } from 'src/components/20-helper-views/snackbar/useSnackbar';
import { NAVBAR_HEIGHT } from 'src/components/templates/page/Navbar.template';
import Page from 'src/components/templates/page/Page.template';
import PageNavbar from 'src/components/templates/page/PageNavbar.template';
import { TypographyFontWeight } from 'src/customizations/themes/10-parts/typography';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import useCallbackWithCtx from 'src/hooks/useCallbackUtils/useCallbackWithCtx';
import useStableCallback from 'src/hooks/useCallbackUtils/useStableCallback';
import useEffectWithCtx from 'src/hooks/useEffectUtils/useEffectWithCtx';
import useI18n from 'src/modules/translations/hooks/useI18n';
import styleUtils from 'src/utils/styles';
import EPBreadCrumbs from '../../components/EPBreadCrumbs';
import { NodeType } from '../../graphql/common/types/node';
import { pageAnalytics } from './analytics';
import AnswerItem from './components/AnswerItem';
import AnswerNotFound from './components/AnswerNotFound';
import ExaminersFeedback from './components/examiners-feedback';
import Loader from './components/Loader';
import QuestionQueryHandler from './components/QuestionQueryHandler';
import useTrackPreparationIntro from './components/track-preparation-intro/useTrackPreparationIntro';
import MarkAsDuplicateDrawer from './containers/MarkAsDuplicateDrawer';
import NavigationBar from './containers/NavigationBar';
import useData from './useData';
const Question = ({ navigation, route }) => {
    const { examLevel, subject, question, qp, questionID } = route.params;
    const [{ styles, theme, dimensions, deviceType }] = useTheme(stylesGenerator);
    const [state, actions] = useData();
    const { translate } = useI18n();
    const handleRetry = useCallbackWithCtx(ctx => {
        ctx.actions.questionDetailsQuery.fetch();
    }, { actions });
    const showAnswerUpdates = useCallbackWithCtx(ctx => {
        ctx.navigation.navigate('EP_AnswerHistory', {
            questionID: ctx.questionID,
        });
    }, { navigation, questionID });
    // Disabling this as this causes the HTMLRenderer (used within AnswerItem)
    // to rerender and that leads to unexpected behaviour (like scrolling back to the top of the page)
    // const [, usageProfileModifiers] = useUsageProfile();
    // useEffectWithCtx(
    //   ({increment}) => {
    //     if (state.questionDetailsQuery.data) {
    //       const timer = setTimeout(() => {
    //         // TODO: I know i should be using `epQuestionsViewed` instead
    //         // of `communityQuestionsViewed`, but using it for now as a
    //         // hack to trigger inAppReview because at this time of writing
    //         // this comment, usage of Community is close to zero and we
    //         // wanted to boost our ratings on App/Play stores.
    //         // In the future, the plan is the introduce OR logic and
    //         // use `epQuestionsViewed` in here and add it to the trigger
    //         // conditions
    //         increment(UsageTrackingParams.communityQuestionsViewed);
    //       }, 3000);
    //       return () => {
    //         clearTimeout(timer);
    //       };
    //     }
    //   },
    //   {increment: usageProfileModifiers.increment},
    //   [state.questionDetailsQuery.data],
    // )
    const { trigger: triggerTrackPreparationIntro } = useTrackPreparationIntro();
    useEffect(() => {
        if (state.questionDetailsQuery.data?.ep_questionDetails.answer?.id) {
            triggerTrackPreparationIntro();
        }
    }, [state.questionDetailsQuery.data?.ep_questionDetails.answer?.id]);
    useEffectWithCtx((ctx) => {
        if (state.questionDetailsQuery.data?.ep_questionDetails.answer?.id) {
            const timer = setTimeout(() => {
                ctx.actions.updateReadMutation.update({
                    variables: {
                        questionID,
                        read: true,
                    },
                });
            }, __DEV__ ? 3_000 : 10_000);
            return () => {
                clearTimeout(timer);
            };
        }
    }, { actions }, [state.questionDetailsQuery.data?.ep_questionDetails.answer?.id]);
    const handleDuplicatePress = useCallbackWithCtx(ctx => {
        if (!ctx.state.questionDetailsQuery.data) {
            return;
        }
        const duplicateQuestion = ctx.state.questionDetailsQuery.data.ep_questionDetails;
        bottomDrawerHelperActions.show({
            component: (React.createElement(MarkAsDuplicateDrawer, { duplicateQuestion: duplicateQuestion })),
        });
    }, { state });
    const ancestorsDetails = state.questionDetailsQuery.data?.ep_questionDetails?.ancestorsDetails;
    const subjectDetails = subject ??
        ancestorsDetails?.writtenSubject ??
        ancestorsDetails?.oralsSubject;
    const qpDetails = qp ?? (ancestorsDetails?.writtenQP || ancestorsDetails?.oralsQP);
    const questionDetails = state.questionDetailsQuery.data?.ep_questionDetails || question;
    const breadCrumbsLoading = !subject && state.questionDetailsQuery.loading;
    const scrollViewRef = useRef(null);
    useEffect(() => {
        if (questionID) {
            setTimeout(() => {
                scrollTo(scrollViewRef, {
                    animated: true,
                    y: 0,
                });
            }, 300);
        }
    }, [questionID]);
    const questionUserData = state.questionDetailsQuery.data?.ep_questionDetails.userData;
    const handleBookmarkQuestion = useStableCallback(() => {
        if (questionUserData) {
            actions.updateBookmarkMutation.update({
                variables: {
                    questionID,
                    bookmark: !questionUserData?.bookmarked
                }
            });
        }
    });
    return (React.createElement(Page, null,
        React.createElement(Column, { flex: true },
            React.createElement(CustomScrollView, { ref: scrollViewRef, forceCollapse: deviceType.isPhone, containerStyle: styleUtils.flex, renderCollapsibleHeader: () => (React.createElement(Column, { bbw: 1 },
                    React.createElement(PageNavbar, { backType: "back", title: translate('Question'), onBackPress: navigation.goBack, actions: [
                            state.markAsDuplicateVisible && {
                                title: 'Mark As Duplicate',
                                Icon: props => React.createElement(FeatherIcon, { name: "copy", ...props }),
                                onPress: handleDuplicatePress,
                            },
                            questionUserData && {
                                title: 'Bookmark this Question',
                                Icon: props => React.createElement(BookmarkIcon, { selected: questionUserData.bookmarked, ...props }),
                                onPress: handleBookmarkQuestion,
                            },
                            questionDetails?.type === NodeType.WRITTEN_QUESTION && {
                                title: 'Play',
                                Icon(props) {
                                    return React.createElement(FeatherIcon, { name: "play-circle", ...props });
                                },
                                onPress() {
                                    pageAnalytics
                                        .setWidget('Navbar')
                                        .setTarget('TTS_Play')
                                        .click();
                                    actions.handlePlaylistPress();
                                },
                            },
                        ], maxVisibleActions: 3 }),
                    React.createElement(EPBreadCrumbs, { loading: breadCrumbsLoading, ancestorsDetails: ancestorsDetails, knownNodes: {
                            examLevel,
                            subject: subjectDetails,
                            qp: qpDetails,
                            question: questionDetails,
                        }, visibleCount: 3 }))), collapsibleHeaderHeight: NAVBAR_HEIGHT, renderCollapsibleFooter: () => React.createElement(NavigationBar, null), collapsibleFooterHeight: 62 + dimensions.safeAreaInsets.bottom, contentContainerStyle: {
                    paddingBottom: NAVBAR_HEIGHT + 28 /* Breadcrumbs height */,
                }, onRefresh: handleRetry, refreshing: !!(state.questionDetailsQuery.loading &&
                    state.questionDetailsQuery.data), horizontal: false },
                __DEV__ && (React.createElement(Row, { xAxis: "space-between", yAxis: "center", style: styles.devHeader, spacing: 12 },
                    React.createElement(Typography, { fontWeight: TypographyFontWeight.BOLD, selectable: true, color: theme.palette.typography.dark },
                        "Question ID: ",
                        questionID),
                    React.createElement(Button, { variant: "text", noIconSpacing: true, textContainerStyle: styles.copyBtn, onPress: () => {
                            Clipboard.setString(questionID);
                            snackbarHelperActions.show({
                                message: 'Copied Question ID',
                                variant: 'NEUTRAL',
                                duration: 'SHORT',
                            });
                        } }, "Copy"))),
                React.createElement(QuestionQueryHandler, { question: question, queryState: state.questionDetailsQuery, onRetry: handleRetry, onEditPress: actions.handleQuestionEditPress }),
                React.createElement(Divider, { mb: theme.spacing.standard.xs }),
                React.createElement(QueryHandler, { queryState: state.questionDetailsQuery, LoaderComponent: Loader, ErrorComponent: React.createElement(GenericServerError, { error: state.questionDetailsQuery.error, onRetry: handleRetry }), DataComponent: React.createElement(Column, null,
                        state.tabs.tabs.length > 1 && (React.createElement(TabHeader, { ph: theme.spacing.page, activeIndex: state.tabs.activeIndex, tabs: state.tabs.tabs, onChange: (index) => {
                                pageAnalytics
                                    .setWidget('TabHeader')
                                    .setTarget(state.tabs.tabs[index].id)
                                    .click();
                                actions.tabs.setActiveIndex(index);
                            } })),
                        React.createElement(Tabs, { activeTab: state.tabs.tabs[state.tabs.activeIndex]?.id },
                            React.createElement(Tab, { id: "ANSWER" },
                                React.createElement(Column, { pv: theme.spacing.standard.sm }, questionDetails?.answer ? (React.createElement(AnswerItem, { question: questionDetails, updateAnswerLoading: state.updateAnswerMutation.loading, onEdit: actions.handleUpdateAnswer, onShowUpdates: showAnswerUpdates })) : (React.createElement(AnswerNotFound, { createAnswerLoading: state.createAnswerMutation.loading, onCreateAnswer: actions.handleCreateAnswer })))),
                            React.createElement(Tab, { id: "EXAMINERS_FEEDBACK" },
                                React.createElement(ExaminersFeedback, { question: questionDetails })))) })))));
};
const stylesGenerator = createThemedStyle(({ theme }) => ({
    container: {},
    breadCrumbs: {
        paddingVertical: theme.spacing.standard.xxs,
    },
    devHeader: {
        paddingHorizontal: theme.spacing.page,
        backgroundColor: 'pink',
    },
    copyBtn: {
        padding: 0,
    },
}));
export default Question;
