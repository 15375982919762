import React from 'react';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import FieldCard from 'src/components/10-atoms/Card/FieldCard';
import Typography from 'src/components/10-atoms/typography/Typography';
import useI18n from 'src/modules/translations/hooks/useI18n';
import CircularProgressBar from 'src/components/10-atoms/progress-bar/CircularProgressBar';
import { getSubjectAnswerCompletion } from 'src/modules/exam-preparation/graphql/subject/utils';
const NoSubjects = ({ subject, onPress }) => {
    const [{ theme }] = useTheme(stylesGenerator);
    const { translate } = useI18n();
    const answerCompletion = getSubjectAnswerCompletion(subject);
    return (React.createElement(FieldCard, { title: React.createElement(Typography, null, translate('Past Question Papers')), onPress: onPress },
        React.createElement(CircularProgressBar, { size: theme.iconSize.lg, value: answerCompletion })));
};
const stylesGenerator = createThemedStyle(({}) => ({}));
export default NoSubjects;
