import { useNavigation, useRoute } from '@react-navigation/native';
import useStableCallback from 'src/hooks/useCallbackUtils/useStableCallback';
import { pageAnalytics } from '../../../analytics';
import useQBankQuestionSiblings from './useQBankQuestionSiblings';
export default function useData() {
    const navigation = useNavigation();
    const route = useRoute();
    const { navigationData = { sequence: 0 } } = route.params;
    const [siblingsQueryState] = useQBankQuestionSiblings(navigationData, navigationData.sequence + 2);
    const currQuestionIndex = navigationData.sequence;
    const prevQuestion = siblingsQueryState.data?.list[currQuestionIndex - 1];
    const nextQuestion = siblingsQueryState.data?.list[currQuestionIndex + 1];
    const handlePrevPress = useStableCallback(() => {
        pageAnalytics.setWidget('QBank_NavigationBar').setTarget('Prev').click();
        if (prevQuestion) {
            navigation.setParams({
                questionID: prevQuestion.id,
                question: undefined,
                navigationData: {
                    ...navigationData,
                    sequence: navigationData.sequence - 1,
                },
            });
        }
    });
    const handleNextPress = useStableCallback(() => {
        pageAnalytics.setWidget('QBank_NavigationBar').setTarget('Next').click();
        if (nextQuestion) {
            navigation.setParams({
                questionID: nextQuestion.id,
                question: undefined,
                navigationData: {
                    ...navigationData,
                    sequence: navigationData.sequence + 1,
                },
            });
        }
    });
    const state = {
        insufficientData: !navigationData?.filters?.ancestorID,
        prevQuestion,
        nextQuestion,
        siblingQuery: siblingsQueryState,
    };
    const actions = {
        handlePrevPress,
        handleNextPress,
    };
    return [state, actions];
}
