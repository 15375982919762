import React from 'react';
import BottomDrawerLayout from 'src/components/20-helper-views/bottom-drawer/BottomDrawerLayout';
import Button from 'src/components/10-atoms/button/Button';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import CircularProgressBar from 'src/components/10-atoms/progress-bar/CircularProgressBar';
import RowCenter from 'src/components/10-atoms/layout/RowCenter';
import ULItem from 'src/components/10-atoms/typography/ULItem';
import Column from 'src/components/10-atoms/layout/Column';
const QuestionAnsweredProgressIntroDrawer = ({ onConfirm }) => {
    const [{ theme }] = useTheme(stylesGenerator);
    return (React.createElement(BottomDrawerLayout, null,
        React.createElement(BottomDrawerLayout.Title, null, "Questions answered progress"),
        React.createElement(BottomDrawerLayout.ScrollableBody, null,
            React.createElement(RowCenter, { pv: theme.spacing.standard.sm },
                React.createElement(CircularProgressBar, { value: 75, size: 72 })),
            React.createElement(Column, { spacing: theme.spacing.standard.xs },
                React.createElement(ULItem, null, "Progress bars show answered question percentages"),
                React.createElement(ULItem, null, "Filter by answered / unanswered questions in the subject's Q Bank"))),
        React.createElement(BottomDrawerLayout.Actions, null,
            React.createElement(Button, { flex: true, onPress: onConfirm }, "Cool, Got it"))));
};
const stylesGenerator = createThemedStyle(({}) => ({}));
export default QuestionAnsweredProgressIntroDrawer;
