import { useNavigation, useRoute } from "@react-navigation/native";
import { useEffect, useMemo, useRef, useState } from "react";
import useSearch from "src/hooks/useSearch";
import { QuestionSearchSortBy } from "src/modules/exam-preparation/graphql/question/types";
import useQBankQuery from "./useQBankQuery";
import useIsRegisteredUser from "src/modules/authentication/hooks/useIsRegisteredUser";
import usePersistedState from "src/hooks/globalState/usePersistedState";
import StorageKeys from "src/lib/storage/storageKeys";
import isEqual from 'lodash/isEqual';
import omit from 'lodash/omit';
import omitKeys from "src/utils/object/omitKeys";
import useStableCallback from "src/hooks/useCallbackUtils/useStableCallback";
import condRemoveKeys from "src/utils/object/condRemoveKeys";
import analytics from "../../analytics";
export default function useData(subjectID) {
    const route = useRoute();
    const isRegisteredUser = useIsRegisteredUser();
    const [savedFilters, setSavedFilters] = usePersistedState(StorageKeys.epQBankSavedFilters, {
        query: '',
        answered: undefined,
        sortBy: QuestionSearchSortBy.TOPICS,
        qpStartDate: undefined,
        unread: false,
        bookmarked: false,
    });
    const saveFilters = useStableCallback(() => {
        setSavedFilters(omit(filters, ['ancestorID']));
    });
    const initialFilters = route.params.filters ?? savedFilters;
    const [filters, setFilters] = useState({
        ancestorID: subjectID,
        query: initialFilters?.query ?? '',
        answered: initialFilters?.answered,
        sortBy: initialFilters?.sortBy ?? QuestionSearchSortBy.TOPICS,
        qpStartDate: initialFilters?.qpStartDate ?? undefined,
        unread: isRegisteredUser ? initialFilters?.unread : false,
        bookmarked: isRegisteredUser ? initialFilters?.bookmarked : false,
    });
    const prevFilters = useRef(filters);
    useEffect(() => {
        if (!isEqual(condRemoveKeys(omitKeys(prevFilters.current, ['ancestorID'])), condRemoveKeys(omitKeys(filters, ['ancestorID'])))) {
            Object.keys(omitKeys(filters, ['ancestorID'])).forEach(key => {
                if (prevFilters.current[key] !== filters[key]) {
                    analytics
                        .setWidget('QBank_Filters')
                        .setTarget(key)
                        .change({ value: String(filters[key]) });
                }
            });
        }
        prevFilters.current = filters;
    }, [filters]);
    const [searchState, searchActions] = useSearch('debounce', 300, filters.query);
    useEffect(() => {
        setFilters(prevState => ({
            ...prevState,
            query: searchState.deferredVal,
        }));
    }, [searchState.deferredVal]);
    const filtersChanged = useMemo(() => {
        return !isEqual(condRemoveKeys(savedFilters), condRemoveKeys(omitKeys(filters, ['ancestorID'])));
    }, [savedFilters, filters]);
    const [qBankQueryState, qBankQueryActions] = useQBankQuery(filters);
    const navigation = useNavigation();
    useEffect(() => {
        navigation.setParams({ filters });
    }, [filters]);
    const state = {
        listQuery: qBankQueryState.listQuery,
        listQueryVars: qBankQueryState.listQueryVars,
        filters,
        search: searchState,
        filtersChanged,
    };
    const actions = {
        listQuery: qBankQueryActions,
        setFilters,
        search: searchActions,
        saveFilters,
    };
    return [state, actions];
}
