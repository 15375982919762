import React from 'react';
import { ScrollView } from 'react-native';
import Divider from 'src/components/10-atoms/Divider';
import FeatherIcon from 'src/components/10-atoms/icons/FeatherIcon';
import Row from 'src/components/10-atoms/layout/Row';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import authHelperViewActions from 'src/modules/authentication/helperViewActions';
import styleUtils from 'src/utils/styles';
import AnsweredFilter from './AnsweredFilter';
import BookmarkedFilter from './BookmarkedFilter';
import QPDateFilter from './QPDateFilter';
import SortBy from './SortBy';
import UnreadFilter from './UnreadFilter';
const QuestionBankFilters = ({ filters, onChange }) => {
    const [{ theme }] = useTheme(stylesGenerator);
    return (React.createElement(Row, { bbw: 1, bg: theme.palette.background.dark },
        React.createElement(ScrollView, { horizontal: true, contentContainerStyle: [styleUtils.flex] },
            React.createElement(Row, { ph: theme.spacing.page, pv: theme.spacing.standard.xs, spacing: theme.spacing.standard.xs, yAxis: 'center' },
                React.createElement(SortBy, { value: filters.sortBy, onChange: sortBy => onChange({ ...filters, sortBy }) }),
                React.createElement(Divider, { orientation: 'vertical' }),
                React.createElement(FeatherIcon, { name: "filter" }),
                React.createElement(Row, { spacing: theme.spacing.standard.xxs, yAxis: 'center' },
                    React.createElement(AnsweredFilter, { value: filters.answered, onChange: answered => onChange({ ...filters, answered }) }),
                    React.createElement(QPDateFilter, { value: filters.qpStartDate, onChange: qpStartDate => onChange({ ...filters, qpStartDate }) }),
                    React.createElement(UnreadFilter, { value: !!filters.unread, onChange: unread => {
                            authHelperViewActions.executePostUserRegistration(() => {
                                onChange({ ...filters, unread });
                            });
                        } }),
                    React.createElement(BookmarkedFilter, { value: !!filters.bookmarked, onChange: bookmarked => {
                            authHelperViewActions.executePostUserRegistration(() => {
                                onChange({ ...filters, bookmarked });
                            });
                        } }))))));
};
const stylesGenerator = createThemedStyle(({}) => ({}));
export default QuestionBankFilters;
