import React from 'react';
import BottomDrawerLayout from 'src/components/20-helper-views/bottom-drawer/BottomDrawerLayout';
import Button from 'src/components/10-atoms/button/Button';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import IllustrationImage from 'src/components/10-atoms/image/IllustrationImage';
import AppAssets from 'src/constants/appAssets';
import Column from 'src/components/10-atoms/layout/Column';
import Typography from 'src/components/10-atoms/typography/Typography';
const SaveFiltersIntroDrawer = ({ onConfirm }) => {
    const [{ theme }] = useTheme(stylesGenerator);
    return (React.createElement(BottomDrawerLayout, null,
        React.createElement(BottomDrawerLayout.Title, null, "Save Filters"),
        React.createElement(BottomDrawerLayout.ScrollableBody, null,
            React.createElement(IllustrationImage, { source: { uri: AppAssets.qbankSaveFilters } }),
            React.createElement(Column, { mt: theme.spacing.standard.xs },
                React.createElement(Typography, null, "Save your current filter settings (search, unread, bookmarked, etc.) and they'll be automatically applied the next time you visit Q Bank"))),
        React.createElement(BottomDrawerLayout.Actions, null,
            React.createElement(Button, { flex: true, onPress: onConfirm }, "Oh great!"))));
};
const stylesGenerator = createThemedStyle(({}) => ({}));
export default SaveFiltersIntroDrawer;
