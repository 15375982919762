import React from 'react';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import DropDownFilterChipLayout from 'src/components/10-atoms/filters/DropdownFilterChip';
import Typography from 'src/components/10-atoms/typography/Typography';
import useStableCallback from 'src/hooks/useCallbackUtils/useStableCallback';
import { bottomDrawerHelperActions } from 'src/components/20-helper-views/bottom-drawer/useBottomDrawer';
import SelectInputDrawer from 'src/components/10-atoms/form/inputs/SelectInput/SelectInputDrawer';
import { ISelectionType } from 'src/components/10-atoms/form/inputs/SelectInput/types';
const options = [{
        id: undefined,
        title: 'All Question Papers'
    }, {
        id: '1y',
        title: 'Past 1 year',
    }, {
        id: '2y',
        title: 'Past 2 years',
    }, {
        id: '3y',
        title: 'Past 3 years',
    }, {
        id: '4y',
        title: 'Past 4 years',
    }, {
        id: '5y',
        title: 'Past 5 years',
    }];
const QPDateFilter = ({ value, onChange }) => {
    const [{}] = useTheme(stylesGenerator);
    const selectedOption = options.find(option => option.id === value);
    const label = selectedOption?.title ?? options[0].title;
    const showOptions = useStableCallback(() => {
        bottomDrawerHelperActions.show({
            component: (React.createElement(SelectInputDrawer, { title: 'Select Question Papers range', type: ISelectionType.SINGLE, value: selectedOption, options: options, onChange: option => {
                    bottomDrawerHelperActions.hide();
                    onChange(option.id);
                }, onCancel: bottomDrawerHelperActions.hide }))
        });
    });
    return (React.createElement(DropDownFilterChipLayout, { selected: selectedOption?.id !== undefined, onPress: showOptions },
        React.createElement(Typography, null, label)));
};
const stylesGenerator = createThemedStyle(({}) => ({}));
export default QPDateFilter;
