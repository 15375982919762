import React from 'react';
import Button from 'src/components/10-atoms/button/Button';
import ListItemLayout from 'src/components/10-atoms/content/list-item/ListItemLayout';
import AudioIcon from 'src/components/10-atoms/icons/AudioIcon';
import FeatherIcon from 'src/components/10-atoms/icons/FeatherIcon';
import IllustrationImage from 'src/components/10-atoms/image/IllustrationImage';
import Column from 'src/components/10-atoms/layout/Column';
import Row from 'src/components/10-atoms/layout/Row';
import CustomScrollView from 'src/components/10-atoms/list-views/CustomScrollView';
import Typography from 'src/components/10-atoms/typography/Typography';
import BottomDrawerLayout from 'src/components/20-helper-views/bottom-drawer/BottomDrawerLayout';
import AppAssets from 'src/constants/appAssets';
import { TypographyVariant } from 'src/customizations/themes/10-parts/typography';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import useI18n from 'src/modules/translations/hooks/useI18n';
import styleUtils from 'src/utils/styles';
const PlaylistIntroDrawer = ({ onConfirm, }) => {
    const [{ theme }] = useTheme(stylesGenerator);
    const { translate } = useI18n();
    return (React.createElement(BottomDrawerLayout, null,
        React.createElement(BottomDrawerLayout.Title, null, translate('Question & Answer Playlist')),
        React.createElement(CustomScrollView, { containerStyle: styleUtils.flex },
            React.createElement(BottomDrawerLayout.Body, { mb: theme.spacing.standard.md },
                React.createElement(IllustrationImage, { source: { uri: AppAssets.ttsIntro } }),
                React.createElement(Column, { mt: theme.spacing.standard.lg },
                    React.createElement(Row, { xAxis: "center", pv: theme.spacing.standard.md },
                        React.createElement(Typography, { align: "center", variant: TypographyVariant.PAGE_TITLE }, "Why read, when you can listen \uD83C\uDF89\uD83C\uDF89\uD83C\uDF89")),
                    React.createElement(Column, { spacing: theme.spacing.standard.sm },
                        React.createElement(Typography, null, "You can do it in 3 easy steps:"),
                        React.createElement(ListItemLayout.Icon, { Icon: React.createElement(FeatherIcon, { name: "file-text" }) }, "Open a Question Paper or a Topic"),
                        React.createElement(ListItemLayout.Icon, { Icon: React.createElement(FeatherIcon, { name: "play-circle" }) }, "Click on Play Icon"),
                        React.createElement(ListItemLayout.Icon, { Icon: React.createElement(AudioIcon, null) }, "Sit back, relax & listen"))))),
        React.createElement(BottomDrawerLayout.Actions, null,
            React.createElement(Button, { flex: true, onPress: onConfirm }, translate('Got it')))));
};
const stylesGenerator = createThemedStyle(({}) => ({}));
export default PlaylistIntroDrawer;
