import React from 'react';
import Button from 'src/components/10-atoms/button/Button';
import ListItemLayout from 'src/components/10-atoms/content/list-item/ListItemLayout';
import BookmarkIcon from 'src/components/10-atoms/icons/BookmarkIcon';
import FeatherIcon from 'src/components/10-atoms/icons/FeatherIcon';
import HistoryIcon from 'src/components/10-atoms/icons/HistoryIcon';
import ReadIcon from 'src/components/10-atoms/icons/ReadIcon';
import IllustrationImage from 'src/components/10-atoms/image/IllustrationImage';
import Column from 'src/components/10-atoms/layout/Column';
import Typography from 'src/components/10-atoms/typography/Typography';
import BottomDrawerLayout from 'src/components/20-helper-views/bottom-drawer/BottomDrawerLayout';
import AppAssets from 'src/constants/appAssets';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import useIsRegisteredUser from 'src/modules/authentication/hooks/useIsRegisteredUser';
const TrackPreparationIntroDrawer = ({ onClose, onLogin }) => {
    const [{ theme }] = useTheme(stylesGenerator);
    const isRegisteredUser = useIsRegisteredUser();
    return (React.createElement(BottomDrawerLayout, null,
        React.createElement(BottomDrawerLayout.Title, null, "Track your Preparation"),
        React.createElement(BottomDrawerLayout.ScrollableBody, null,
            React.createElement(IllustrationImage, { source: { uri: AppAssets.trackPreparationProgressIntro } }),
            React.createElement(Column, { mt: theme.spacing.standard.sm, spacing: theme.spacing.standard.sm },
                React.createElement(Typography, null, "Here's how it works:"),
                !isRegisteredUser && (React.createElement(ListItemLayout.Icon, { Icon: React.createElement(FeatherIcon, { name: "log-in" }) }, "Login via Google / Apple signin")),
                React.createElement(ListItemLayout.Icon, { Icon: React.createElement(HistoryIcon, null) }, isRegisteredUser ? ("We keep a history of the questions you've read") : ("After you signin, we keep a history of the questions you've read")),
                React.createElement(ListItemLayout.Icon, { Icon: React.createElement(ReadIcon, null) }, "You can then find all the Unread questions in the Q Bank"),
                React.createElement(ListItemLayout.Icon, { Icon: React.createElement(BookmarkIcon, null) }, "Additionally you can bookmark important question for easy access within your Q Bank"))),
        React.createElement(BottomDrawerLayout.Actions, { mt: theme.spacing.standard.sm }, isRegisteredUser ? (React.createElement(Button, { flex: true, onPress: onClose }, "Okay, thanks \uD83D\uDC4D\uD83C\uDFFC")) : (React.createElement(Button, { flex: true, onPress: onLogin, RightIcon: (props) => React.createElement(FeatherIcon, { name: "log-in", ...props }) }, "Let me Login")))));
};
const stylesGenerator = createThemedStyle(({}) => ({}));
export default TrackPreparationIntroDrawer;
