import React from 'react';
import { StyleSheet } from 'react-native';
import Button from 'src/components/10-atoms/button/Button';
import FeatherIcon from 'src/components/10-atoms/icons/FeatherIcon';
import ColumnCenter from 'src/components/10-atoms/layout/ColumnCenter';
import BackdropLoader from 'src/components/10-atoms/loaders/BackdropLoader';
import Typography from 'src/components/10-atoms/typography/Typography';
import { imageViewHelperActions } from 'src/components/20-helper-views/image-viewer/useImageViewer';
import { TypographyVariant } from 'src/customizations/themes/10-parts/typography';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import withRemove from 'src/hoc/withRemove';
import ThumbnailLayout from './ThumbnailLayout';
import withThemeProvider from 'src/customizations/themes/hoc/withThemeProvider';
const ImageThumbnail = ({ src, loading = false, error = null, onRetry, disablePreview = false, onPress, ...thumbnailLayoutProps }) => {
    const [{ styles, theme }] = useTheme(stylesGenerator);
    return (React.createElement(ThumbnailLayout, { bg: theme.palette.background.main, ...(!disablePreview && {
            onPress: () => {
                if (src) {
                    if (onPress)
                        onPress();
                    else {
                        imageViewHelperActions.show({
                            images: [
                                {
                                    uri: src,
                                },
                            ],
                        });
                    }
                }
            },
        }), ...thumbnailLayoutProps },
        React.createElement("img", { style: styles.img, src: src }),
        loading ? (React.createElement(BackdropLoader, { size: "small" })) : error && onRetry ? (React.createElement(ErrorOverlay, { onRetry: onRetry })) : null));
};
const ErrorOverlay = ({ onRetry }) => {
    const [{ theme }] = useTheme();
    return (React.createElement(ColumnCenter, { style: [StyleSheet.absoluteFill], bg: theme.palette.backdrop.darker, spacing: theme.spacing.standard.xs },
        React.createElement(ColumnCenter, null,
            React.createElement(FeatherIcon, { name: "alert-triangle", color: theme.palette.negative.main, size: theme.iconSize.lg }),
            React.createElement(Typography, { variant: TypographyVariant.CAPTION, color: theme.palette.primary.contrast, align: "center" }, "Upload Failed")),
        React.createElement(Button, { variant: "text", size: "xs", onPress: onRetry, LeftIcon: props => React.createElement(FeatherIcon, { name: "rotate-ccw", ...props }) }, "Retry")));
};
const stylesGenerator = createThemedStyle(({}) => ({
    img: {
        width: '100%',
        height: '100%',
        objectFit: 'contain',
    },
}));
export default withRemove(withThemeProvider(ImageThumbnail, 'light'));
