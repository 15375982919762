import { gql } from '@apollo/client';
import { addTypePolicies } from 'src/lib/graphql/cache';
import useGQLPaginatedQuery from 'src/lib/graphql/hooks/useGQLPaginatedQuery';
import paginatedListFieldPolicy from 'src/lib/graphql/utils/paginatedListFieldPolicy';
import { EP_QUESTION_DATA_FIELDS } from './getQuestionDetails';
const QUESTION_LIST_FOR_QP_QUERY = gql `
  query EP_QuestionListForQP(
    $questionPaperID: ID!
    $pagination: PaginationOptions!
  ) {
    ep_questionListForQP(
      questionPaperID: $questionPaperID
      pagination: $pagination
    ) {
      totalCount
      list {
        id
        type
        data {
          ...EPQuestionDataFields
        }
        translationInfo {
          ...TranslationInfoFields
        }
        attributes {
          answered
        }
        userData {
          read
          bookmarked
        }
        questionPaper {
          data {
            dateNL
            label
          }
        }
        duplicates {
          questionPaper {
            data {
              dateNL
              label
            }
          }
        }
      }
    }
  }

  ${EP_QUESTION_DATA_FIELDS}
`;
export function useQuestionListForQPQuery(options) {
    return useGQLPaginatedQuery(QUESTION_LIST_FOR_QP_QUERY, 'ep_questionListForQP', options);
}
addTypePolicies({
    Query: {
        fields: {
            ep_questionListForQP: paginatedListFieldPolicy([
                'questionPaperID',
            ]),
        },
    },
});
