;
export var QuestionSearchSortBy;
(function (QuestionSearchSortBy) {
    QuestionSearchSortBy[QuestionSearchSortBy["RELEVANCE"] = 1] = "RELEVANCE";
    QuestionSearchSortBy[QuestionSearchSortBy["TOPICS"] = 2] = "TOPICS";
    QuestionSearchSortBy[QuestionSearchSortBy["QP_DATE"] = 3] = "QP_DATE";
    QuestionSearchSortBy[QuestionSearchSortBy["DUPLICATES_COUNT"] = 4] = "DUPLICATES_COUNT";
    QuestionSearchSortBy[QuestionSearchSortBy["MOST_READ"] = 5] = "MOST_READ";
    QuestionSearchSortBy[QuestionSearchSortBy["MOST_BOOKMARKED"] = 6] = "MOST_BOOKMARKED";
})(QuestionSearchSortBy || (QuestionSearchSortBy = {}));
