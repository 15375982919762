import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import React from 'react';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
// import {renderCommunityTabRoutes} from 'src/modules/community/communityTabRoutes';
import { renderDebugTabRoutes } from 'src/modules/debug/debugTabRoutes';
import { renderExamPreparationTabRoutes } from 'src/modules/exam-preparation/examPreparationTabRoutes';
import useUnreadNotificationBgFetcher from 'src/modules/notification/hooks/useUnreadNotificationBgFetcher';
import { renderNotificationTabRoutes } from 'src/modules/notification/notificationTabRoutes';
import { renderUserTabRoutes } from 'src/modules/user/userTabRoutes';
import { RootStack } from './RootNavigation';
import { renderSTCTabRoutes } from 'src/modules/support-the-creators/stcTabRoutes';
import { InitialRouteName } from './config';
export const RootTab = createBottomTabNavigator();
const TabNavigator = () => {
    const [{ theme, deviceType, dimensions }] = useTheme(stylesGenerator);
    useUnreadNotificationBgFetcher();
    return (React.createElement(RootTab.Navigator, { screenOptions: {
            headerShown: false,
            tabBarActiveTintColor: theme.palette.primary.dark,
            tabBarInactiveTintColor: theme.palette.typography.primary,
            tabBarStyle: {
                backgroundColor: theme.palette.background.main,
                paddingBottom: dimensions.safeAreaInsets.bottom,
                height: 48 + dimensions.safeAreaInsets.bottom,
            },
            tabBarAllowFontScaling: true,
            tabBarHideOnKeyboard: true,
            tabBarShowLabel: !deviceType.isPhone,
        }, initialRouteName: InitialRouteName },
        renderExamPreparationTabRoutes(),
        renderNotificationTabRoutes(),
        renderUserTabRoutes(),
        renderSTCTabRoutes(),
        __DEV__ && renderDebugTabRoutes()));
};
export const stylesGenerator = createThemedStyle(() => ({
    drawerItem: {
        marginHorizontal: 0,
        marginVertical: 0,
        borderRadius: 0,
        paddingVertical: 4,
    },
}));
export const renderTabStackRoutes = () => (React.createElement(RootStack.Screen, { name: "Tabs", component: TabNavigator }));
