import React from 'react';
import SearchInput from 'src/components/10-atoms/form/inputs/SearchInput';
import Column from 'src/components/10-atoms/layout/Column';
import { PRESSABLE_SIZE } from 'src/components/templates/PressableArea';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import QuestionList from 'src/modules/exam-preparation/containers/QuestionList';
import styleUtils from 'src/utils/styles';
import QuestionBankFilters from './Filters';
import useData from './useData';
import Row from 'src/components/10-atoms/layout/Row';
import Button from 'src/components/10-atoms/button/Button';
import FeatherIcon from 'src/components/10-atoms/icons/FeatherIcon';
import useStableCallback from 'src/hooks/useCallbackUtils/useStableCallback';
import useFeatureIntroDrawer from 'src/components/10-atoms/feature-intro/useFeatureIntroDrawer';
import FTUEKeys from 'src/lib/ftue/keys';
import SaveFiltersIntroDrawer from './SaveFiltersIntro';
import analytics from '../../analytics';
const QBankTab = ({ subjectID, onQuestionPress }) => {
    const [{ deviceType, theme }] = useTheme(stylesGenerator);
    const [state, actions] = useData(subjectID);
    const [, saveFiltersIntroActions] = useFeatureIntroDrawer(FTUEKeys.EP_SAVE_QBANK_FILTERS_INTRO);
    const handleSaveFilters = useStableCallback(async () => {
        analytics
            .setWidget('QBank_Filters')
            .setTarget('SaveFilters')
            .click();
        await saveFiltersIntroActions.show(React.createElement(SaveFiltersIntroDrawer, { onConfirm: saveFiltersIntroActions.hide }));
        actions.saveFilters();
    });
    return (React.createElement(Column, { style: [styleUtils.ovfHidden, styleUtils.flex] },
        React.createElement(QuestionList, { variant: 'question-paper', queryState: state.listQuery, queryActions: actions.listQuery, variables: state.listQueryVars, onQuestionPress: onQuestionPress, forceCollapse: deviceType.isPhone, renderCollapsibleHeader: () => (React.createElement(Column, null,
                React.createElement(Row, { ph: theme.spacing.page, pt: theme.spacing.standard.xs, bg: theme.palette.background.dark, spacing: theme.spacing.standard.xs },
                    React.createElement(SearchInput, { containerStyle: styleUtils.flex, style: { backgroundColor: theme.palette.background.main }, placeholder: 'Search for questions...', value: state.search.value, onChange: actions.search.change, onClear: () => actions.search.change('') }),
                    React.createElement(Button, { disabled: !state.filtersChanged, LeftIcon: props => React.createElement(FeatherIcon, { name: 'save', ...props }), onPress: handleSaveFilters })),
                React.createElement(QuestionBankFilters, { filters: state.filters, onChange: actions.setFilters }))), collapsibleHeaderHeight: (theme.pressableSize.sm + 2 * theme.spacing.standard.xs)
                + (PRESSABLE_SIZE + 2 * theme.spacing.standard.xs) })));
};
const stylesGenerator = createThemedStyle(({}) => ({}));
export default QBankTab;
