import React from 'react';
import { Dimensions, View } from 'react-native';
import Column from 'src/components/10-atoms/layout/Column';
import ModalBase from 'src/components/10-atoms/modals/ModalBase';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import useQueuedVisibility from 'src/hooks/useQueuedVisibility';
import Row from 'src/components/10-atoms/layout/Row';
import FeatherIcon from 'src/components/10-atoms/icons/FeatherIcon';
import Button from 'src/components/10-atoms/button/Button';
import { MAX_VIEWPORT_WIDTH } from 'src/constants/dimensions';
const FadeDuration = 200;
const SlideDuration = 300;
export const BottomDrawerAnimationDuration = FadeDuration + SlideDuration;
export const BottomDrawerSlideHeight = Dimensions.get('window').height * 0.85;
const BottomDrawer = ({ visible, onClose, component, dismissable = true, }) => {
    const [{ styles, theme, deviceType }] = useTheme(stylesGenerator);
    const [queuedVisible, queuedVisibilityActions] = useQueuedVisibility(visible);
    const handleClose = () => {
        queuedVisibilityActions.handleHideCompleted();
        onClose();
    };
    return deviceType.isPhone ? (React.createElement(ModalBase, { animationIn: "slideInUp", animationOut: "slideOutDown", style: styles.modal, propagateSwipe: true, isVisible: queuedVisible, onDismiss: dismissable ? handleClose : () => { }, swipeDirection: dismissable ? 'down' : undefined },
        React.createElement(Column, { xAxis: "stretch", bg: theme.palette.background.main, sab: true, style: styles.container },
            dismissable && React.createElement(View, { style: styles.gestureBar }),
            component))) : (React.createElement(ModalBase, { animationIn: "fadeInUp", animationOut: "fadeOutDown", style: styles.modal, propagateSwipe: true, isVisible: queuedVisible, onDismiss: dismissable ? handleClose : () => { }, swipeDirection: dismissable ? 'down' : undefined },
        React.createElement(Column, { xAxis: "stretch", bg: theme.palette.background.main, sab: true, pb: theme.spacing.standard.sm, style: styles.container },
            dismissable && (React.createElement(Row, { bg: theme.palette.background.main, xAxis: "flex-end", yAxis: "center", style: styles.closeBtnContainer },
                React.createElement(Button, { variant: "text", onPress: handleClose },
                    React.createElement(FeatherIcon, { name: "x" })))),
            component)));
};
const stylesGenerator = createThemedStyle(({ theme, dimensions, deviceType }) => {
    return {
        modal: {
            justifyContent: deviceType.isPhone ? 'flex-end' : 'center',
            ...(!deviceType.isPhone && { alignItems: 'center' }),
        },
        container: deviceType.isPhone
            ? {
                maxHeight: dimensions.viewPort.height * 0.9,
                borderTopLeftRadius: theme.borderRadius.standard.lg,
                borderTopRightRadius: theme.borderRadius.standard.lg,
                paddingTop: theme.spacing.standard.sm,
            }
            : {
                borderRadius: theme.borderRadius.standard.lg,
                overflow: 'hidden',
                width: MAX_VIEWPORT_WIDTH - 192,
                maxWidth: '90%',
                maxHeight: dimensions.viewPort.height * 0.9,
            },
        closeBtnContainer: {
            borderTopLeftRadius: theme.borderRadius.standard.lg,
            borderTopRightRadius: theme.borderRadius.standard.lg,
        },
        gestureBar: {
            height: 4,
            borderRadius: 2,
            width: 72,
            backgroundColor: theme.palette.background.darker,
            position: 'absolute',
            top: 6,
            alignSelf: 'center',
        },
    };
});
export default BottomDrawer;
