/**
 * QSI: Question Status indicators
 */
import React from 'react';
import Button from 'src/components/10-atoms/button/Button';
import ListItemLayout from 'src/components/10-atoms/content/list-item/ListItemLayout';
import BookmarkIcon from 'src/components/10-atoms/icons/BookmarkIcon';
import FeatherIcon from 'src/components/10-atoms/icons/FeatherIcon';
import ReadIcon from 'src/components/10-atoms/icons/ReadIcon';
import IllustrationImage from 'src/components/10-atoms/image/IllustrationImage';
import Column from 'src/components/10-atoms/layout/Column';
import Typography from 'src/components/10-atoms/typography/Typography';
import BottomDrawerLayout from 'src/components/20-helper-views/bottom-drawer/BottomDrawerLayout';
import AppAssets from 'src/constants/appAssets';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
const QSIIntroDrawer = ({ onConfirm }) => {
    const [{ theme }] = useTheme(stylesGenerator);
    return (React.createElement(BottomDrawerLayout, null,
        React.createElement(BottomDrawerLayout.Title, null, "Introducing Question status indicators"),
        React.createElement(BottomDrawerLayout.ScrollableBody, null,
            React.createElement(IllustrationImage, { source: { uri: AppAssets.questionStatusIntro }, mb: theme.spacing.standard.sm }),
            React.createElement(Column, { spacing: theme.spacing.standard.sm },
                React.createElement(Typography, null, "Enhance your exam preparation by monitoring the following question status indicators, which are personalized for you:"),
                React.createElement(ListItemLayout.Icon, { Icon: React.createElement(BookmarkIcon, null) }, "Indicates that the you've bookmarked this question"),
                React.createElement(ListItemLayout.Icon, { Icon: React.createElement(ReadIcon, null) }, "Indicates that you've already read this question"),
                React.createElement(ListItemLayout.Icon, { Icon: React.createElement(FeatherIcon, { name: "check" }) }, "Indicates that the question has been answered"))),
        React.createElement(BottomDrawerLayout.Actions, null,
            React.createElement(Button, { onPress: onConfirm, flex: true }, "Alright, I understand"))));
};
const stylesGenerator = createThemedStyle(({}) => ({}));
export default QSIIntroDrawer;
