export default function condRemoveKeys(obj, excludeValues = [null, undefined]) {
    return Object.entries(obj).reduce((result, [key, val]) => {
        if (!excludeValues.includes(val)) {
            result[key] = val;
        }
        else if (typeof val === 'object') {
            if (Array.isArray(val)) {
                result[key] = val.map(item => condRemoveKeys(item, excludeValues));
            }
            else {
                result[key] = condRemoveKeys(val);
            }
        }
        return result;
    }, {});
}
