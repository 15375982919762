import { StorageScope } from './types';
const StorageKeys = {
    cachePrefix: {
        scope: StorageScope.USER,
        key: '/cache',
    },
    ftuePrefix: {
        scope: StorageScope.DEVICE,
        key: '/ftue',
    },
    preferencesPrefix: {
        scope: StorageScope.USER,
        key: '/preferences',
    },
    debugSettings: {
        scope: StorageScope.DEVICE,
        key: '/debug/settings',
    },
    drawerInitialRouteName: {
        scope: StorageScope.USER,
        key: '/drawer/initial-route-name',
    },
    deviceInfo: {
        scope: StorageScope.USER,
        key: '/device/info',
    },
    usageProfile: {
        scope: StorageScope.DEVICE,
        key: '/usage-profile',
    },
    usageProfileThresholdCondState: {
        scope: StorageScope.DEVICE,
        key: '/usage-profile/threshold-cond-state',
    },
    trackerLastOpenedDate: {
        scope: StorageScope.DEVICE,
        key: '/trackers/last-opened-date',
    },
    configService: {
        scope: StorageScope.USER,
        key: '/config-service',
    },
    notificationPermission: {
        scope: StorageScope.DEVICE,
        key: '/notification/permission',
    },
    fcmToken: {
        scope: StorageScope.USER,
        key: '/notification/fcmToken',
    },
    requestETag: {
        scope: StorageScope.USER,
        key: '/api/request/etag',
    },
    userPreferences: {
        scope: StorageScope.USER,
        key: '/user/preferences',
    },
    trackProgressIntroCtx: {
        scope: StorageScope.DEVICE,
        key: '/ep/question/track-progress/intro/ctx'
    },
    epQBankSavedFilters: {
        scope: StorageScope.DEVICE,
        key: '/ep/qbank/saved-filters'
    },
    // Translations related keys
    preferredLanguage: {
        scope: StorageScope.DEVICE,
        key: '/translations/selected-lang',
    },
    languageTranslations: {
        scope: StorageScope.DEVICE,
        key: '/translations/lang',
    },
    communityTopicsUsedFrequency: {
        scope: StorageScope.DEVICE,
        key: '/community/topics/frequency',
    },
    communityCategoriesColor: {
        scope: StorageScope.DEVICE,
        key: '/community/category/color',
    },
    communityPrevSelectedFilterViewID: {
        scope: StorageScope.USER,
        key: '/community/filter-view/prev-selected/id',
    },
    examPrepSubjectLastUsedGroup: {
        scope: StorageScope.USER,
        key: '/exam-prep/subject/last-used-group',
    },
    unreadNotificationCount: {
        scope: StorageScope.USER,
        key: '/notification/unread',
    },
    // Auth
    userAuthToken: {
        scope: StorageScope.DEVICE,
        key: '/user/auth-token',
    },
    userDetails: {
        scope: StorageScope.USER,
        key: '/user/details',
    },
    epSelectedExamLevel: {
        scope: StorageScope.USER,
        key: '/exam-prep/selected-exam-level',
    },
    ttsPreferences: {
        scope: StorageScope.DEVICE,
        key: '/tts/preferences',
    },
    // WebViewServer
    wvWebAssetsUpdateAvailable: {
        scope: StorageScope.DEVICE,
        key: '/webview/web-assets/update-available',
    },
    wvWebAssetsUpdateLastModified: {
        scope: StorageScope.DEVICE,
        key: '/webview/web-assets/update-last-modified',
    },
};
export default StorageKeys;
