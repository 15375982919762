import { useEffect, useRef } from 'react';
import useTabs from 'src/components/10-atoms/tabs/useTabs';
import StorageKeys from 'src/lib/storage/storageKeys';
import usePersistedState from 'src/hooks/globalState/usePersistedState';
import useI18n from 'src/modules/translations/hooks/useI18n';
import { useSubjectDetailsQuery } from '../../graphql/subject/getDetails';
import { useNavigation, useRoute } from '@react-navigation/native';
export default function useData(subjectID) {
    const route = useRoute();
    const { tabID } = route.params;
    const { translate } = useI18n();
    const [subjectDetailsQueryState, subjectDetailsQueryActions] = useSubjectDetailsQuery(subjectID, {
        lazy: true,
    });
    const tabs = useRef([
        {
            id: 'PAPERS',
            label: translate('Papers'),
        },
        {
            id: 'TOPICS',
            label: translate('Topics'),
        },
        {
            id: 'QUESTION_BANK',
            label: translate('Q Bank'),
        },
        {
            id: 'DIAGRAMS',
            label: translate('Diagrams'),
        },
    ]).current;
    const [lastUsedTab, setLastUsedTab] = usePersistedState(StorageKeys.examPrepSubjectLastUsedGroup, 0);
    const initialIndex = tabID ? tabs.findIndex((tab) => tab.id === tabID) : lastUsedTab ?? 0;
    const [tabsState, tabsActions] = useTabs({
        tabs,
        activeIndex: initialIndex,
    });
    useEffect(() => {
        setLastUsedTab(tabsState.activeIndex);
    }, [tabsState.activeIndex]);
    const navigation = useNavigation();
    useEffect(() => {
        navigation.setParams({
            tabID: tabsState.tabs[tabsState.activeIndex].id,
        });
    }, [tabsState.activeIndex]);
    const state = {
        tabs: tabsState,
        subjectDetailsQuery: subjectDetailsQueryState,
    };
    const actions = {
        tabs: tabsActions,
        subjectDetailsQuery: subjectDetailsQueryActions,
    };
    return [state, actions];
}
