import React, { useEffect } from 'react';
import { ActivityIndicator } from 'react-native';
import FeatherIcon from 'src/components/10-atoms/icons/FeatherIcon';
import Column from 'src/components/10-atoms/layout/Column';
import Page from 'src/components/templates/page/Page.template';
import PageNavbar from 'src/components/templates/page/PageNavbar.template';
import useI18n from 'src/modules/translations/hooks/useI18n';
import styleUtils from 'src/utils/styles';
import EPBreadCrumbs from '../../components/EPBreadCrumbs';
import QuestionList from '../../containers/QuestionList';
import { NodeType } from '../../graphql/common/types/node';
import useQSIIntro from '../QP/components/QSIIntroDrawer/useQSIIntro';
import { pageAnalytics } from './analytics';
import useData from './useData';
import usePlaylistIntro from 'src/lib/playlist/hooks/usePlaylistIntro';
import useStableCallback from 'src/hooks/useCallbackUtils/useStableCallback';
const TopicQuestions = ({ navigation }) => {
    const { translate } = useI18n();
    const [state, actions] = useData();
    const { trigger: triggerPlaylistIntro } = usePlaylistIntro({
        onConfirm() {
            pageAnalytics.setWidget('TTS_Intro').setTarget('Get_Started').click();
        },
    });
    const handleSpeakPress = useStableCallback(async () => {
        await triggerPlaylistIntro();
        actions.playlist.play();
    });
    const { trigger: triggerQSIIntro } = useQSIIntro();
    useEffect(() => {
        if (state.listQuery.data?.totalCount) {
            triggerQSIIntro();
        }
    }, [state.listQuery.data?.totalCount]);
    return (React.createElement(Page, null,
        React.createElement(Column, { bbw: 1 },
            React.createElement(PageNavbar, { backType: "back", title: translate('Topic-wise Questions'), onBackPress: navigation.goBack, actions: [
                    state.subject?.type === NodeType.WRITTEN_SUBJECT && {
                        // eslint-disable-next-line react/no-unstable-nested-components
                        Icon(props) {
                            return state.playlist.playlistQuery.loading ? (React.createElement(ActivityIndicator, { size: "small" })) : (React.createElement(FeatherIcon, { name: "play-circle", ...props }));
                        },
                        title: 'Speak',
                        onPress() {
                            pageAnalytics.setWidget('Navbar').setTarget('TTS_Play').click();
                            handleSpeakPress();
                        },
                    },
                ] }),
            React.createElement(EPBreadCrumbs, { loading: state.subjectDetailsQuery.loading, knownNodes: {
                    examLevel: state.examLevel,
                    subject: state.subject,
                    topic: state.topic,
                }, visibleCount: 2 })),
        React.createElement(QuestionList, { style: styleUtils.containerHeight, variant: "topic-details", containerStyle: styleUtils.flex, queryState: state.listQuery, queryActions: actions.listQuery, variables: state.listQueryVariables, onQuestionPress: actions.gotoQuestionDetails })));
};
export default TopicQuestions;
