import React from 'react';
import Button from 'src/components/10-atoms/button/Button';
import useFeatureIntroDrawer from 'src/components/10-atoms/feature-intro/useFeatureIntroDrawer';
import FeatherIcon from 'src/components/10-atoms/icons/FeatherIcon';
import Column from 'src/components/10-atoms/layout/Column';
import { overlayHelperViewActions } from 'src/components/20-helper-views/overlay/useOverlay';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import useStableCallback from 'src/hooks/useCallbackUtils/useStableCallback';
import FTUEKeys from 'src/lib/ftue/keys';
import { NodeType } from 'src/modules/exam-preparation/graphql/common/types/node';
import styleUtils from 'src/utils/styles';
import QPUploadIntroDrawer from '../../components/QPUploadIntroDrawer';
import QPList from '../QPList';
import QPFormOverlay from './QPForm';
const QPTab = ({ subjectID, subject, onQPPress, style, ...columnProps }) => {
    const [{}] = useTheme(stylesGenerator);
    const [qpUploadIntroFTUEState, qpUploadIntroFTUEActions] = useFeatureIntroDrawer(FTUEKeys.EP_QP_UPLOAD_INTRO);
    const handleAddQP = useStableCallback(async () => {
        if (qpUploadIntroFTUEState.ftue) {
            await qpUploadIntroFTUEActions.show(React.createElement(QPUploadIntroDrawer, { onDismiss: qpUploadIntroFTUEActions.hide }));
        }
        overlayHelperViewActions.show({
            component: (React.createElement(QPFormOverlay, { subjectID: subjectID, onClose: overlayHelperViewActions.hide, onComplete: overlayHelperViewActions.hide })),
        });
    });
    return (React.createElement(Column, { style: [styleUtils.flex, style], ...columnProps },
        React.createElement(QPList, { containerStyle: [styleUtils.flex], contentContainerStyle: [styleUtils.spaceBtwHeadNBody, styleUtils.floatingBtnGutter], subjectID: subjectID, onQPPress: onQPPress, groupBy: subject?.type === NodeType.WRITTEN_SUBJECT ? 'year' : 'month', subjectType: subject?.type ?? NodeType.WRITTEN_SUBJECT }),
        React.createElement(Button, { variant: "float", LeftIcon: props => React.createElement(FeatherIcon, { name: "plus", ...props }), onPress: handleAddQP }, "Question Paper")));
};
const stylesGenerator = createThemedStyle(({}) => ({}));
export default QPTab;
