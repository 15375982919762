import React from 'react';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import useI18n from 'src/modules/translations/hooks/useI18n';
import ActionsBar from 'src/components/templates/ActionsBar';
import Button from 'src/components/10-atoms/button/Button';
import FeatherIcon from 'src/components/10-atoms/icons/FeatherIcon';
const NavigationBar = ({ loading, error, prevDisabled, onPrevPress, nextDisabled, onNextPress, ...actionsBarProps }) => {
    const [{ deviceType }] = useTheme(stylesGenerator);
    const { translate } = useI18n();
    return error ? null : (React.createElement(ActionsBar, { btw: 1, ...actionsBarProps },
        React.createElement(Button, { variant: "text", size: "sm", LeftIcon: props => React.createElement(FeatherIcon, { name: "chevron-left", ...props }), loading: loading, disabled: prevDisabled, onPress: onPrevPress }, translate('Prev')),
        React.createElement(Button, { variant: "text", size: "sm", RightIcon: props => React.createElement(FeatherIcon, { name: "chevron-right", ...props }), loading: loading, disabled: nextDisabled, onPress: onNextPress }, translate('Next'))));
};
const stylesGenerator = createThemedStyle(({}) => ({}));
export default NavigationBar;
