import { gql } from "@apollo/client";
import useGQLMutation from "src/lib/graphql/hooks/useGQLMutation";
const UPDATE_QUESTION_READ_MUTATION = gql `
  mutation EP_UpdateQuestionRead(
    $questionID: ID!
    $read: Boolean!
  ) {
    ep_updateQuestionRead(id: $questionID, read: $read)
  }
`;
export function useEPUpdateQuestionRead(options) {
    return useGQLMutation(UPDATE_QUESTION_READ_MUTATION, {
        ...options,
        update(cache, result, { variables }) {
            if (result.data?.ep_updateQuestionRead && variables) {
                cache.modify({
                    id: cache.identify({
                        id: variables.questionID,
                        __typename: 'EPQuestion',
                    }),
                    fields: {
                        userData(existing) {
                            return {
                                ...existing,
                                read: variables.read
                            };
                        }
                    }
                });
            }
        }
    });
}
