import React from 'react';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import FilterChipLayout from './FilterChipLayout';
const BooleanFilterChip = ({ value, onChange, style, ...filterChipProps }) => {
    const [{ theme }] = useTheme(stylesGenerator);
    return (React.createElement(FilterChipLayout, { bcl: value
            ? theme.palette.primary.dark
            : theme.palette.border.main, bg: value
            ? theme.palette.primary.lightest
            : theme.palette.background.main, ...filterChipProps, onPress: () => onChange(!value) }));
};
const stylesGenerator = createThemedStyle(({}) => ({}));
export default BooleanFilterChip;
