import React from 'react';
import { bottomDrawerHelperActions } from "src/components/20-helper-views/bottom-drawer/useBottomDrawer";
import usePersistedState from "src/hooks/globalState/usePersistedState";
import useStableCallback from "src/hooks/useCallbackUtils/useStableCallback";
import StorageKeys from "src/lib/storage/storageKeys";
import useIsRegisteredUser from "src/modules/authentication/hooks/useIsRegisteredUser";
import TrackPreparationIntroDrawer from "./TrackPreparationIntro";
import authHelperViewActions from "src/modules/authentication/helperViewActions";
export default function useTrackPreparationIntro() {
    const isRegisteredUser = useIsRegisteredUser();
    const [, setTrackProgressIntroCtx] = usePersistedState(StorageKeys.trackProgressIntroCtx);
    const trigger = useStableCallback(() => {
        setTrackProgressIntroCtx(prevState => {
            if (!prevState) {
                prevState = {
                    questionVisitedCount: 0,
                };
            }
            else {
                prevState.questionVisitedCount++;
            }
            if ((prevState.questionVisitedCount === 1) || (!isRegisteredUser && prevState.questionVisitedCount % 10 === 0)) {
                bottomDrawerHelperActions.show({
                    component: (React.createElement(TrackPreparationIntroDrawer, { onClose: bottomDrawerHelperActions.hide, onLogin: () => {
                            bottomDrawerHelperActions.hide();
                            authHelperViewActions.triggerUserRegistration('Login to track your Preparation');
                        } }))
                });
            }
            return {
                ...prevState,
            };
        });
    });
    return { trigger };
}
