import React from 'react';
import BottomDrawerLayout from 'src/components/20-helper-views/bottom-drawer/BottomDrawerLayout';
import Button from 'src/components/10-atoms/button/Button';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import CustomScrollView from 'src/components/10-atoms/list-views/CustomScrollView';
import styleUtils from 'src/utils/styles';
import ULItem from 'src/components/10-atoms/typography/ULItem';
import Column from 'src/components/10-atoms/layout/Column';
import IllustrationImage from 'src/components/10-atoms/image/IllustrationImage';
import AppAssets from 'src/constants/appAssets';
const QBankIntroDrawer = ({ onCancel, onConfirm }) => {
    const [{ theme }] = useTheme(stylesGenerator);
    return (React.createElement(BottomDrawerLayout, null,
        React.createElement(BottomDrawerLayout.Title, null, "Introducing Q Bank"),
        React.createElement(CustomScrollView, { containerStyle: styleUtils.flex },
            React.createElement(BottomDrawerLayout.Body, { spacing: theme.spacing.standard.sm },
                React.createElement(IllustrationImage, { source: { uri: AppAssets.qBankIntro } }),
                React.createElement(Column, null,
                    React.createElement(ULItem, null, "All questions for the subject on a single, scrollable page"),
                    React.createElement(ULItem, null, "Filter by answered questions to optimize your preparation time"),
                    React.createElement(ULItem, null, "Filter by unread questions to ensure complete preparation"),
                    React.createElement(ULItem, null, "Limit your preparation content by filtering the question papers to a specific timeframe"),
                    React.createElement(ULItem, null, "and many more...")))),
        React.createElement(BottomDrawerLayout.Actions, null,
            React.createElement(Button, { variant: "text", onPress: onCancel }, "Will check later"),
            React.createElement(Button, { onPress: onConfirm }, "Try it now"))));
};
const stylesGenerator = createThemedStyle(({}) => ({}));
export default QBankIntroDrawer;
