import React from 'react';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import Row from 'src/components/10-atoms/layout/Row';
import LoaderBase from 'src/components/10-atoms/loaders/LoaderBase';
const DiagramListLoading = ({ cols = 3, ...rowProps }) => {
    const [{ theme }] = useTheme(stylesGenerator);
    return (React.createElement(Row, { ...rowProps, spacingX: theme.spacing.standard.sm, ph: theme.spacing.page, pv: theme.spacing.standard.sm }, new Array(cols).fill(0).map((_, i) => (React.createElement(LoaderBase, { key: i, flex: true, style: { aspectRatio: '1' } })))));
};
const stylesGenerator = createThemedStyle(({}) => ({}));
export default DiagramListLoading;
