import { useMemo } from "react";
import useTheme from "src/customizations/themes/hooks/useTheme";
import useSearch from "src/hooks/useSearch";
import { useDiagramSearchQuery } from "src/modules/exam-preparation/graphql/diagram/search";
function getGridList(list, cols) {
    const result = [];
    for (let i = 0; i < list.length; ++i) {
        const row = Math.floor(i / cols);
        (result[row] = result[row] || []).push(list[i]);
    }
    return result;
}
export default function useData(subjectID) {
    const [listQueryState, listQueryActions] = useDiagramSearchQuery();
    const [{ deviceType }] = useTheme();
    const gridCols = deviceType.isPhone ? 2 : 3;
    const gridListQueryState = useMemo(() => ({
        ...listQueryState,
        data: listQueryState.data
            ? {
                ...listQueryState.data,
                list: getGridList(listQueryState.data.list, gridCols),
            }
            : listQueryState.data,
    }), [listQueryState]);
    const [searchState, searchActions] = useSearch('throttle');
    const listQueryVar = useMemo(() => {
        return {
            ancestorID: subjectID,
            pagination: {
                offset: 0,
                limit: gridCols * 5,
            },
            query: searchState.deferredVal,
        };
    }, [subjectID, searchState.deferredVal]);
    const state = {
        gridCols,
        gridListQuery: gridListQueryState,
        listQuery: listQueryState,
        listQueryVar,
        search: searchState,
    };
    const actions = {
        listQuery: listQueryActions,
        search: searchActions
    };
    return [state, actions];
}
