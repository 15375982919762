import React from 'react';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import withPress from 'src/hoc/withPress';
import withThemeProvider from 'src/customizations/themes/hoc/withThemeProvider';
const DiagramItem = ({ diagram }) => {
    const [{ styles }] = useTheme(stylesGenerator, { diagram }, [diagram]);
    return (React.createElement("div", { key: diagram.id, style: styles.container }));
};
const stylesGenerator = createThemedStyle(({ theme }, { diagram }) => ({
    container: {
        backgroundImage: `url('${diagram.data.imageURL}')`,
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundColor: theme.palette.background.main,
        width: '100%',
        height: '100%',
        border: `1px solid ${theme.palette.border.main}`,
        borderRadius: `${theme.borderRadius.standard.md}px`,
        boxSizing: 'border-box',
    }
}));
export default withPress(withThemeProvider(DiagramItem, 'light'));
