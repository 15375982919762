import React from 'react';
import useFeatureIntroDrawer from "src/components/10-atoms/feature-intro/useFeatureIntroDrawer";
import FTUEKeys from "src/lib/ftue/keys";
import QSIIntroDrawer from './QSIIntroDrawer';
import useStableCallback from 'src/hooks/useCallbackUtils/useStableCallback';
// QSI: Question Status Indicator
export default function useQSIIntro() {
    const [, qsiFeatureIntroActions] = useFeatureIntroDrawer(FTUEKeys.EP_QUESTION_STATUS_INDICATOR_INTRO + '-2');
    const trigger = useStableCallback(() => {
        return qsiFeatureIntroActions.show(React.createElement(QSIIntroDrawer, { onConfirm: qsiFeatureIntroActions.hide }));
    });
    return { trigger };
}
