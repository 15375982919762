import React, { useState } from 'react';
import Button from 'src/components/10-atoms/button/Button';
import BottomDrawerLayout from 'src/components/20-helper-views/bottom-drawer/BottomDrawerLayout';
import SelectInputGroup from '../select-input-group/SelectInputGroup';
import { ISelectionType } from './types';
const SelectInputDrawer = ({ title, type, onCancel, value, onChange, options, }) => {
    const [selectionValue, setSelectionValue] = useState(value);
    const handleChange = (newValue) => {
        if (type === ISelectionType.SINGLE) {
            onChange(newValue);
        }
        else {
            setSelectionValue(newValue);
        }
    };
    const handleConfirm = () => {
        onChange(selectionValue);
    };
    // const renderActions = () => {
    //   if (type === ISelectionType.SINGLE) {
    //     return (
    //       <BottomDrawerLayout.Actions xAxis="center" yAxis="center">
    //         <Button variant="text" onPress={onCancel}>
    //           Cancel
    //         </Button>
    //       </BottomDrawerLayout.Actions>
    //     );
    //   } else {
    //     return (
    //       <BottomDrawerLayout.Actions>
    //         <Button variant="text" onPress={onCancel}>
    //           Cancel
    //         </Button>
    //         <Button onPress={handleConfirm}>Confirm</Button>
    //       </BottomDrawerLayout.Actions>
    //     );
    //   }
    // };
    return (React.createElement(BottomDrawerLayout, null,
        React.createElement(BottomDrawerLayout.Title, null, title),
        React.createElement(BottomDrawerLayout.Body, { ph: 0, pt: 0 },
            React.createElement(SelectInputGroup, { type: type, value: selectionValue, onChange: handleChange, options: options })),
        type === ISelectionType.MULTI && (React.createElement(BottomDrawerLayout.Actions, null,
            React.createElement(Button, { onPress: handleConfirm }, "Confirm")))));
};
export default SelectInputDrawer;
