import { useState } from 'react';
import useEffectWithCtx from 'src/hooks/useEffectUtils/useEffectWithCtx';
export default function useFetchGQLListTill(listState, listActions, listVariables, expectedListLength, skip) {
    const [completed, setCompleted] = useState(false);
    useEffectWithCtx(ctx => {
        if (skip)
            return;
        if (listState.data) {
            if (listState.data.totalCount >= expectedListLength) {
                if (listState.data.list.length < expectedListLength) {
                    ctx.setCompleted(false);
                    ctx.listActions.fetchMore(ctx.listVariables);
                    return;
                }
            }
            ctx.setCompleted(true);
        }
        else {
            ctx.listActions.refresh(ctx.listVariables);
        }
    }, { listState, listActions, listVariables, setCompleted }, [listState.data, expectedListLength, skip]);
    const state = {
        ...listState,
        loading: !completed || listState.loading,
    };
    const actions = {};
    return [state, actions];
}
