import React from 'react';
import { Path } from 'react-native-svg';
import withDefaultIconProps from './withDefaultIconProps';
import SVGIcon from './SVGIcon';
import useTheme from 'src/customizations/themes/hooks/useTheme';
const BookmarkIcon = ({ color, selected, ...rest }) => {
    const [{ theme }] = useTheme();
    return (React.createElement(SVGIcon, { ...rest },
        React.createElement(Path, { stroke: color, strokeLinecap: "round", strokeLinejoin: "round", strokeWidth: selected ? 0 : 2, d: "m19 21-7-5-7 5V5a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2v16Z", fill: selected ? theme.palette.primary.main : 'transparent' })));
};
export default withDefaultIconProps(BookmarkIcon);
