import React from 'react';
import ColumnCard from 'src/components/10-atoms/Card/ColumnCard';
import GenericServerError from 'src/components/10-atoms/GenericServerError';
import QueryHandler from 'src/components/10-atoms/QueryHandler';
import Label from 'src/components/10-atoms/form/Label';
import Column from 'src/components/10-atoms/layout/Column';
import ListLoaderLayout from 'src/components/10-atoms/loaders/ListLoaderLayout';
import { PRESSABLE_SIZE } from 'src/components/templates/PressableArea';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import useCallbackWithCtx from 'src/hooks/useCallbackUtils/useCallbackWithCtx';
import useI18n from 'src/modules/translations/hooks/useI18n';
import SubjectItem from './Item';
import NoSubjects from './NoSubjects';
const SubjectListAPIHandler = ({ hasSubjects, queryState, onRetry, onSubjectPress, ...columnCardProps }) => {
    const [{ theme }] = useTheme(stylesGenerator);
    const { translate } = useI18n();
    const handlePastQPsPress = useCallbackWithCtx(ctx => {
        ctx.onSubjectPress(ctx.queryState.data.ep_subjectList.list[0]);
    }, { onSubjectPress, queryState });
    return (React.createElement(QueryHandler, { queryState: queryState, LoaderComponent: React.createElement(ColumnCard, { spacing: theme.spacing.standard.sm, ...columnCardProps },
            React.createElement(Label, null, translate('Subjects')),
            React.createElement(ListLoaderLayout, { ph: 0, loaderItemHeight: PRESSABLE_SIZE, count: 3 })), ErrorComponent: React.createElement(GenericServerError, { error: queryState.error, onRetry: onRetry }), DataComponent: () => !hasSubjects ? (React.createElement(NoSubjects, { subject: queryState.data.ep_subjectList.list[0], onPress: handlePastQPsPress })) : (React.createElement(ColumnCard, { spacing: theme.spacing.standard.sm, ...columnCardProps },
            React.createElement(Label, null, translate('Subjects')),
            React.createElement(Column, null, (queryState.data?.ep_subjectList.list ?? []).map(subject => (React.createElement(SubjectItem, { key: subject.id, subject: subject, onPress: () => onSubjectPress(subject) })))))) }));
};
const stylesGenerator = createThemedStyle(({}) => ({}));
export default SubjectListAPIHandler;
