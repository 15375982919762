import { useRoute } from '@react-navigation/native';
import React from 'react';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import QPNavigationBar from './QPNavigationbar';
import SearchNavigationBar from './SearchNavigationbar';
import TopicNavigationBar from './TopicNavigationbar';
import QBankNavigationBar from './QBankNavigationBar';
const NavigationBar = () => {
    const [{}] = useTheme(stylesGenerator);
    const route = useRoute();
    const { navigatedFrom } = route.params;
    switch (navigatedFrom) {
        case 'QP_QUESTIONS':
            return React.createElement(QPNavigationBar, null);
        case 'TOPIC_QUESTIONS':
            return React.createElement(TopicNavigationBar, null);
        case 'SEARCH_QUESTIONS':
            return React.createElement(SearchNavigationBar, null);
        case 'Q_BANK_QUESTIONS':
            return React.createElement(QBankNavigationBar, null);
        default:
            return null;
    }
};
const stylesGenerator = createThemedStyle(({}) => ({}));
export default NavigationBar;
