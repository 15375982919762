import React from 'react';
import DropDownFilterChipLayout from 'src/components/10-atoms/filters/DropdownFilterChip';
import SelectInputDrawer from 'src/components/10-atoms/form/inputs/SelectInput/SelectInputDrawer';
import { ISelectionType } from 'src/components/10-atoms/form/inputs/SelectInput/types';
import Typography from 'src/components/10-atoms/typography/Typography';
import { bottomDrawerHelperActions } from 'src/components/20-helper-views/bottom-drawer/useBottomDrawer';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import useStableCallback from 'src/hooks/useCallbackUtils/useStableCallback';
import { QuestionSearchSortBy } from 'src/modules/exam-preparation/graphql/question/types';
import SortIcon from 'src/components/10-atoms/icons/SortIcon';
const options = [{
        id: QuestionSearchSortBy.TOPICS,
        title: 'Topics'
    }, {
        id: QuestionSearchSortBy.QP_DATE,
        title: 'Question Paper Date'
    }, {
        id: QuestionSearchSortBy.DUPLICATES_COUNT,
        title: 'Most Repeated'
    }, {
        id: QuestionSearchSortBy.MOST_READ,
        title: 'Most Read (past 3 days)'
    }, {
        id: QuestionSearchSortBy.MOST_BOOKMARKED,
        title: 'Most Bookmarked (past 3 days)'
    }, {
        id: QuestionSearchSortBy.RELEVANCE,
        title: 'Search Relevance'
    }];
const SortBy = ({ value, onChange }) => {
    const [{ theme }] = useTheme(stylesGenerator);
    const selectedOption = options.find(option => option.id === value);
    const label = selectedOption?.title ?? options[0].title;
    const showOptions = useStableCallback(() => {
        bottomDrawerHelperActions.show({
            component: (React.createElement(SelectInputDrawer, { title: 'Sort Questions by', type: ISelectionType.SINGLE, value: selectedOption, options: options, onChange: option => {
                    bottomDrawerHelperActions.hide();
                    onChange(option.id);
                }, onCancel: bottomDrawerHelperActions.hide }))
        });
    });
    return (React.createElement(DropDownFilterChipLayout, { selected: false, spacing: theme.spacing.standard.xxs, onPress: showOptions },
        React.createElement(SortIcon, { size: theme.iconSize.sm }),
        React.createElement(Typography, null, label)));
};
const stylesGenerator = createThemedStyle(({}) => ({}));
export default SortBy;
