import React, { useEffect } from 'react';
import Column from 'src/components/10-atoms/layout/Column';
import Row from 'src/components/10-atoms/layout/Row';
import Tab from 'src/components/10-atoms/tabs/Tab';
import TabHeader from 'src/components/10-atoms/tabs/TabHeader';
import Tabs from 'src/components/10-atoms/tabs/Tabs';
import Page from 'src/components/templates/page/Page.template';
import PageNavbar from 'src/components/templates/page/PageNavbar.template';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import useI18n from 'src/modules/translations/hooks/useI18n';
import GenericServerError from 'src/components/10-atoms/GenericServerError';
import QueryHandler from 'src/components/10-atoms/QueryHandler';
import useCallbackWithCtx from 'src/hooks/useCallbackUtils/useCallbackWithCtx';
import { isWeb } from 'src/utils/platform';
import EPBreadCrumbs from '../../components/EPBreadCrumbs';
import QPTab from './container/QPTab';
import TopicList from './container/TopicList';
import useData from './useData';
import QBankTab from './container/QBankTab';
import useStableCallback from 'src/hooks/useCallbackUtils/useStableCallback';
import useFTUE from 'src/lib/ftue/hooks/useFTUE';
import FTUEKeys from 'src/lib/ftue/keys';
import { bottomDrawerHelperActions } from 'src/components/20-helper-views/bottom-drawer/useBottomDrawer';
import QBankIntroDrawer from './components/QBankIntroDrawer';
import analytics from './analytics';
import DiagramsTab from './container/DiagramsTab';
const Subject = ({ navigation, route }) => {
    const { subject, examLevel, subjectID } = route.params;
    const [state, actions] = useData(subjectID);
    const [{ styles, theme }] = useTheme(stylesGenerator);
    const { translate } = useI18n();
    useEffect(() => {
        if (!subject) {
            actions.subjectDetailsQuery.fetch();
        }
    }, []);
    const [qbankIntroFTUE, qbankIntroFTUEActions] = useFTUE(FTUEKeys.EP_QBANK_INTRO);
    useEffect(() => {
        if (state.subjectDetailsQuery.data?.ep_subjectDetails.id) {
            if (qbankIntroFTUE) {
                bottomDrawerHelperActions.show({
                    component: (React.createElement(QBankIntroDrawer, { onCancel: () => {
                            bottomDrawerHelperActions.hide();
                            qbankIntroFTUEActions.markComplete();
                        }, onConfirm: () => {
                            bottomDrawerHelperActions.hide();
                            qbankIntroFTUEActions.markComplete();
                            actions.tabs.setActiveIndex(state.tabs.tabs.findIndex(tab => tab.id === 'QUESTION_BANK') ?? 0);
                        } })),
                    onClose() {
                        qbankIntroFTUEActions.markComplete();
                    },
                });
            }
        }
    }, [state.subjectDetailsQuery.data?.ep_subjectDetails.id]);
    const ancestorsDetails = state.subjectDetailsQuery.data?.ep_subjectDetails.ancestorsDetails;
    const subjectDetails = subject || state.subjectDetailsQuery.data?.ep_subjectDetails;
    const examLevelDetails = examLevel || ancestorsDetails?.examLevel;
    const handleTopicPress = useCallbackWithCtx((ctx, topic) => {
        if (!ctx.subject) {
            return;
        }
        analytics.setWidget('TopicList').setTarget('Topic').click();
        navigation.navigate('EP_TopicQuestions', {
            ...(!isWeb && {
                examLevel: ctx.examLevel,
                subject: ctx.subject,
            }),
            ancestorID: ctx.subject.id,
            topic: topic.id,
        });
    }, {
        subject: subjectDetails,
        examLevel: examLevelDetails,
    });
    const handleQPPress = useCallbackWithCtx((ctx, questionPaper) => {
        analytics
            .setWidget('QuestionPaperList')
            .setTarget('QuestionPaper')
            .click();
        navigation.navigate('EP_QP', {
            ...(!isWeb && {
                examLevel: ctx.examLevel,
                subject: ctx.subject,
                qp: questionPaper,
            }),
            qpID: questionPaper.id,
        });
    }, { examLevel: examLevelDetails, subject: subjectDetails });
    const handleQBankQuestionPress = useStableCallback((question, index) => {
        analytics
            .setWidget('QBank_QuestionList')
            .setTarget('Question')
            .click();
        navigation.navigate('EP_Question', {
            questionID: question.id,
            navigatedFrom: 'Q_BANK_QUESTIONS',
            navigationData: {
                sequence: index,
                filters: {
                    ...route.params.filters,
                    ancestorID: subjectID,
                }
            }
        });
    });
    const handleDiagramRefQuestionPress = useStableCallback((question) => {
        analytics
            .setWidget('Diagram_refQuestionList')
            .setTarget('Question')
            .click();
        // navigation.navigate('EP_Question', {
        //   questionID: question.id,
        //   navigatedFrom: 'Q_BANK_QUESTIONS',
        //   navigationData: {
        //     sequence: index,
        //     filters: {
        //       ...route.params.filters,
        //       ancestorID: subjectID,
        //     }
        //   }
        // })
    });
    const breadCrumbsLoading = !examLevel && state.subjectDetailsQuery.loading;
    return (React.createElement(Page, null,
        React.createElement(Column, { bbw: 1 },
            React.createElement(PageNavbar, { backType: "back", title: translate('Subject'), onBackPress: navigation.goBack }),
            React.createElement(Column, { style: styles.header },
                React.createElement(Row, { alignSelf: 'stretch', yAxis: 'center', ph: theme.spacing.page },
                    React.createElement(EPBreadCrumbs, { loading: breadCrumbsLoading, visibleCount: 1, knownNodes: {
                            examLevel: examLevelDetails,
                            subject: subjectDetails,
                        }, ph: 0, style: { maxWidth: '100%' } })),
                React.createElement(TabHeader, { ph: theme.spacing.standard.xs, tabs: state.tabs.tabs, activeIndex: state.tabs.activeIndex, onChange: (index) => {
                        analytics
                            .setWidget('GroupBy')
                            .setTarget(state.tabs.tabs[index].id)
                            .click();
                        actions.tabs.setActiveIndex(index);
                    } }))),
        React.createElement(QueryHandler, { skip: !!subjectDetails, queryState: state.subjectDetailsQuery, LoaderComponent: null, ErrorComponent: React.createElement(GenericServerError, { onRetry: actions.subjectDetailsQuery.fetch }), DataComponent: React.createElement(Tabs, { activeTab: state.tabs.tabs[state.tabs.activeIndex]?.id },
                React.createElement(Tab, { id: "PAPERS" },
                    React.createElement(QPTab, { subject: subjectDetails, subjectID: subjectID, onQPPress: handleQPPress })),
                React.createElement(Tab, { id: "TOPICS" },
                    React.createElement(TopicList, { subjectID: subjectID, onTopicPress: handleTopicPress })),
                React.createElement(Tab, { id: "QUESTION_BANK" },
                    React.createElement(QBankTab, { subjectID: subjectID, onQuestionPress: handleQBankQuestionPress })),
                React.createElement(Tab, { id: "DIAGRAMS" },
                    React.createElement(DiagramsTab, { subjectID: subjectID, onQuestionPress: handleDiagramRefQuestionPress }))) })));
};
const stylesGenerator = createThemedStyle(({ theme }) => ({
    header: {
        backgroundColor: theme.palette.background.main,
    },
}));
export default Subject;
