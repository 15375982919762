import React from 'react';
import useFeatureIntroDrawer from 'src/components/10-atoms/feature-intro/useFeatureIntroDrawer';
import useStableCallback from 'src/hooks/useCallbackUtils/useStableCallback';
import FTUEKeys from 'src/lib/ftue/keys';
import PlaylistIntroDrawer from '../components/PlaylistIntroDrawer';
export default function usePlaylistIntro(options) {
    const [introFTUEState, introFTUEActions] = useFeatureIntroDrawer(FTUEKeys.PLAYLIST_INTRODUCTION);
    const trigger = useStableCallback(() => {
        if (introFTUEState.ftue) {
            return introFTUEActions.show(React.createElement(PlaylistIntroDrawer, { onConfirm: () => {
                    introFTUEActions.hide();
                    options.onConfirm();
                } }));
        }
    });
    return { trigger };
}
