/**
 * The contents in this file has been copied from:
 * https://github.com/postaljs/postal.request-response/blob/master/src/postal.request-response.js
 *
 * It was done to ensure that it complies with typescript and to make it easier to use in the project.
 * Moreover, jest wasn't able to run this library properly when it was imported.
 */
import { uniqueId } from 'lodash';
let REQ_RES_CHANNEL = 'postal.request-response';
export default function addOnRequestResponse(postal) {
    postal.ChannelDefinition.prototype.request = function (topic, data) {
        const env = {
            topic,
            data,
            headers: {},
        };
        const requestId = uniqueId();
        const replyTopic = requestId;
        const replyChannel = REQ_RES_CHANNEL;
        return new Promise((resolve, reject) => {
            env.headers = env.headers || {};
            env.headers.replyable = true;
            env.headers.requestId = requestId;
            env.headers.replyTopic = replyTopic;
            env.headers.replyChannel = replyChannel;
            let timer;
            postal
                .subscribe({
                channel: replyChannel,
                topic: replyTopic,
                callback(_data, _env) {
                    if (_env.headers && _env.headers.isError) {
                        reject(_data);
                    }
                    else {
                        resolve(_data);
                    }
                    // @ts-ignore
                    timer && clearTimeout(timer);
                },
            })
                .once();
            // if (timeout) {
            //   timer = setTimeout(() => {
            //     sub.unsubscribe();
            //     reject(new Error('Timeout limit exceeded for request.'));
            //   }, timeout);
            // }
            this.publish(env);
        });
    };
    let oldPub = postal.publish;
    postal.publish = function (envelope) {
        if (envelope.headers && envelope.headers.replyable) {
            envelope.reply = function (err, data) {
                postal.publish({
                    channel: envelope.headers.replyChannel,
                    topic: envelope.headers.replyTopic,
                    headers: {
                        isReply: true,
                        isError: !!err,
                        requestId: envelope.headers.requestId,
                        resolverNoCache: true,
                    },
                    data: err || data,
                });
            };
        }
        oldPub.call(this, envelope);
    };
    return postal;
}
