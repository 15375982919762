import ms from 'ms';
import { useMemo } from "react";
import { useEPQuestionSearchQuery } from "src/modules/exam-preparation/graphql/question/questionSearch";
import { QuestionSearchSortBy } from "src/modules/exam-preparation/graphql/question/types";
import { getDateAsUTC } from "src/utils/date";
import analytics from '../../analytics';
const listAnalytics = analytics.setWidget('QBank').setTarget('QuestionList');
export default function useQBankQuery(filters) {
    const [listQueryState, listQueryActions] = useEPQuestionSearchQuery({
        skip: !filters?.ancestorID,
        listAnalytics
    });
    const listQueryVars = useMemo(() => {
        if (!filters)
            return {};
        return {
            query: filters.query ?? '',
            ancestorID: filters.ancestorID,
            withAncestorsDetails: false,
            answered: filters.answered,
            read: filters.unread ? false : undefined,
            bookmarked: filters.bookmarked || undefined,
            sortBy: filters.sortBy ?? QuestionSearchSortBy.TOPICS,
            qpStartDate: getQPStartDate(filters.qpStartDate),
        };
    }, [filters]);
    const state = {
        listQuery: listQueryState,
        listQueryVars,
    };
    return [state, listQueryActions];
}
function getQPStartDate(value) {
    if (!value)
        return undefined;
    return getDateAsUTC(new Date(Date.now() - ms(value)));
}
