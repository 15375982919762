import { gql } from "@apollo/client";
import useGQLMutation from "src/lib/graphql/hooks/useGQLMutation";
const UPDATE_QUESTION_BOOKMARK_MUTATION = gql `
  mutation EP_UpdateQuestionBookmark(
    $questionID: ID!
    $bookmark: Boolean!
  ) {
    ep_updateQuestionBookmark(id: $questionID, bookmark: $bookmark)
  }
`;
export function useEPUpdateQuestionBookmark(options) {
    return useGQLMutation(UPDATE_QUESTION_BOOKMARK_MUTATION, {
        ...options,
        update(cache, result, { variables }) {
            if (result.data?.ep_updateQuestionBookmark && variables) {
                cache.modify({
                    id: cache.identify({
                        id: variables.questionID,
                        __typename: 'EPQuestion',
                    }),
                    fields: {
                        userData(existing) {
                            return {
                                ...existing,
                                bookmarked: variables.bookmark
                            };
                        }
                    }
                });
            }
        }
    });
}
