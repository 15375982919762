import React from 'react';
import Button from 'src/components/10-atoms/button/Button';
import ListItemLayout from 'src/components/10-atoms/content/list-item/ListItemLayout';
import FeatherIcon from 'src/components/10-atoms/icons/FeatherIcon';
import IllustrationImage from 'src/components/10-atoms/image/IllustrationImage';
import Column from 'src/components/10-atoms/layout/Column';
import Typography from 'src/components/10-atoms/typography/Typography';
import BottomDrawerLayout from 'src/components/20-helper-views/bottom-drawer/BottomDrawerLayout';
import AppAssets from 'src/constants/appAssets';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
const QPUploadIntroDrawer = ({ onDismiss }) => {
    const [{ theme }] = useTheme(stylesGenerator);
    return (React.createElement(BottomDrawerLayout, null,
        React.createElement(BottomDrawerLayout.Title, null, "Question paper upload \uD83C\uDF89\uD83C\uDF89\uD83C\uDF89"),
        React.createElement(BottomDrawerLayout.ScrollableBody, { mb: theme.spacing.standard.md },
            React.createElement(IllustrationImage, { source: { uri: AppAssets.qpUploadIntro } }),
            React.createElement(Column, { spacing: theme.spacing.standard.xs, mt: theme.spacing.standard.sm },
                React.createElement(Typography, null, "Upload missing question papers in 3 simple steps:"),
                React.createElement(ListItemLayout.Icon, { Icon: React.createElement(FeatherIcon, { name: "calendar" }) }, "Select the Examination date"),
                React.createElement(ListItemLayout.Icon, { Icon: React.createElement(FeatherIcon, { name: "camera" }) }, "Upload Question paper images"),
                React.createElement(ListItemLayout.Icon, { Icon: React.createElement(FeatherIcon, { name: "check-circle" }) }, "Submit"))),
        React.createElement(BottomDrawerLayout.Actions, null,
            React.createElement(Button, { flex: true, onPress: onDismiss }, "Got it!"))));
};
const stylesGenerator = createThemedStyle(({}) => ({}));
export default QPUploadIntroDrawer;
