import { bottomDrawerHelperActions } from "src/components/20-helper-views/bottom-drawer/useBottomDrawer";
import useStableCallback from "src/hooks/useCallbackUtils/useStableCallback";
import useFTUE from "src/lib/ftue/hooks/useFTUE";
export default function useFeatureIntroDrawer(ftueKey) {
    const [ftue, ftueActions] = useFTUE(ftueKey);
    const state = {
        ftue,
    };
    const actions = {
        show: useStableCallback(async (component) => {
            if (ftue) {
                return new Promise(resolve => {
                    bottomDrawerHelperActions.show({
                        component,
                        onClose() {
                            ftueActions.markComplete();
                            resolve(null);
                        },
                    });
                });
            }
        }),
        hide: useStableCallback(() => {
            bottomDrawerHelperActions.hide();
            ftueActions.markComplete();
        })
    };
    return [state, actions];
}
